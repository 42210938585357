<template>
  <div>
    <b-card-code title="Modifier information">
      <b-container fluid>
        <!-- form -->
        <validation-observer ref="modifierInformatons">
          <b-form>
            <b-row>
              <!-- username -->
              <b-col cols="12">
                <b-form-group label="Nom et prénoms" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="user.name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom et prénom "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger">{{ errorsName }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- email -->
              <b-col cols="12">
                <b-form-group label="Email" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      v-model="user.email"
                      :state="errors.length > 0 ? false : null"
                      type="email"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger">{{ errorsEmail }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- telephone -->
              <b-col cols="12">
                <b-form-group label="Téléphone" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="Téléphone"
                    rules="required"
                  >
                    <b-form-input
                      v-model="user.telephone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Telephone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger">{{ errorsTelephone }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- login button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="updateProfile"
                >
                  Enregistrer
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-container>
    </b-card-code>

    <b-card-code title="Modifier mot de passe">
      <!-- form -->
      <validation-observer ref="modifierMotDePasse">
        <b-form>
          <b-row>
            <!-- old password -->
            <!--
             <b-col cols="12">
            <b-form-group
              label="Password"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Ancien mot de passe"
                vid="Password"
                rules="required"
              >
                <b-form-input
                  id="a-password"
                  v-model="password.last"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                  placeholder="Ancien mot de passe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
-->
            <!-- password -->
            <b-col cols="12">
              <b-form-group label="Nouveau mot de passe" label-for="a-password">
                <validation-provider
                  #default="{ errors }"
                  name="Nouveau mot de passe"
                  vid="Password"
                  rules="required|min:8"
                >
                  <b-form-input
                    id="a-password"
                    v-model="password.newPassword"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Nouveau mot de passe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- confirm password -->
            <b-col cols="12">
              <b-form-group label="Confirmation" label-for="ac-password">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmation"
                  rules="required|confirmed:Password"
                >
                  <b-form-input
                    id="ac-password"
                    v-model="password.newPassword_confirmation"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Confirmation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- reset button -->
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="updatePassword"
              >
                Enregistrer
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>
<script>
import { mapState } from "vuex";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BContainer,
    BTable,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      password: {},
      loading: false,
      errorsEmail: null,
      errorsTelephone: null,
      errorsName: null,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.user.auth,
    }),
    user() {
      return JSON.parse(JSON.stringify(this.auth));
    },
  },
  created() {
    console.log("hello");
    console.log(this.user);
  },
  mounted() {},
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    updateProfile() {
      this.$refs.modifierInformatons.validate().then((success) => {
        if (success) {
          console.log(2222222);
          this.loading = true;
          this.$http
            .put("/users/" + this.auth.id, this.user)
            .then((response) => {
              this.loading = false;
              if (response.data.success) {
                this.showToast("Modification effectuée avec succès", "success");
              } else {
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((err) => {
              var error = err.response.data.errors;
              if (error && error.email) {
                this.errorsEmail = error.email[0];
              }
              if (error && error.name) {
                this.errorsName = error.name[0];
              }
              if (error && error.telephone) {
                this.errorsTelephone = error.telephone[0];
              }
              this.loading = false;
              this.showToast(err.response.data.message, "error");
            });
        }
      });
      //this.user.name = this.auth.name
      //this.user.email = this.auth.email
      //this.user.telephone = this.auth.telephone
      //this.user.resource = this.auth.scope
    },
    updatePassword() {
      this.$refs.modifierMotDePasse.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$http
            .put("/change-password", this.password)
            .then((response) => {
              this.loading = false;
              if (response.data.success) {
                console.log("bien modifié");
                this.showToast("Modification effectuée avec succès", "success");
              } else {
                this.showToast(result.data.message, "danger");
              }
            })
            .catch((err) => {
              this.showToast(err.response.data.message, "error");
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/pages/ui-feather.scss";
</style>